module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Muhamed Khubba","short_name":"Muhamed","start_url":"/","background_color":"#020202","theme_color":"#020202","display":"minimal-ui","icon":"src/images/maeid-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3b89510873ee472c5ff59f40468a0d46"},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"mainColor":"#fff","secondaryColor":"#050505","textColor":"#505050","shade":"#f1f1f1","skinnyColor":"rgb(234, 241, 246)"},"dark":{"mainColor":"#050505","secondaryColor":"#fff","textColor":"#b8b8b8","shade":"#080808","darkBorder":"#151515","skinnyColor":"#101010","snowWhite":"#ebebeb"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{"preventScroll":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
